import React, { useEffect } from "react";
import TextGradientBGFX from "./TextGradientBGFX";

const SideDesc = () => {
  return (
    <div className="px-10 sm:px-20 py-1 pt-28 pb-28" style={{ maxWidth: "560px" }}>
      <div className="mb-24">
        <svg width="172" height="28" viewBox="0 0 172 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.47229 2.57733C2.47229 1.689 2.75868 1.04028 3.33146 0.631183C3.91593 0.210394 4.64651 0 5.52321 0C6.37653 0 7.10127 0.210394 7.69743 0.631183C8.30527 1.04028 8.60919 1.689 8.60919 2.57733C8.60919 3.44229 8.30527 4.091 7.69743 4.52348C7.08958 4.95596 6.36484 5.1722 5.52321 5.1722C4.64651 5.1722 3.91593 4.95596 3.33146 4.52348C2.75868 4.091 2.47229 3.44229 2.47229 2.57733ZM0 27.6493V25.7383H0.420816C1.07542 25.7383 1.66573 25.5805 2.19175 25.2649C2.71777 24.9493 2.98078 24.3298 2.98078 23.4064V12.5009C2.98078 11.6243 2.71193 11.0399 2.17422 10.7477C1.63651 10.4554 1.05204 10.3093 0.420816 10.3093H0V8.39825H8.43385V23.4064C8.43385 24.3298 8.69686 24.9493 9.22288 25.2649C9.76059 25.5805 10.3509 25.7383 10.9938 25.7383H11.4146V27.6493H0Z" fill="#2D1456" />
          <path d="M14.3371 27.6493V8.32671H18.0363L29.4373 22.239H27.643V8.32671H32.0599V27.6493H28.3883L16.9596 13.737H18.754V27.6493H14.3371Z" fill="#2D1456" />
          <path d="M44.9311 28C43.6102 28 42.4004 27.8305 41.3016 27.4915C40.2145 27.1526 39.2793 26.5915 38.4961 25.8084C37.7129 25.0253 37.1051 24.0025 36.6726 22.7401C36.2401 21.4778 36.0238 19.929 36.0238 18.0939C36.0238 16.1536 36.2518 14.5348 36.7077 13.2373C37.1635 11.9399 37.7831 10.8996 38.5663 10.1165C39.3611 9.33333 40.2904 8.77813 41.3542 8.45085C42.4296 8.11188 43.5868 7.94239 44.8259 7.94239C45.9831 7.94239 47.006 8.04174 47.8943 8.24045C48.7827 8.42747 49.525 8.69046 50.1212 9.02943C51.3369 9.73075 51.9447 10.6249 51.9447 11.712C51.9447 12.0977 51.8746 12.4776 51.7343 12.8516C51.6057 13.2139 51.3719 13.5354 51.0329 13.8159C50.6823 14.0964 50.203 14.3244 49.5951 14.4997C48.999 14.675 48.2333 14.7627 47.2982 14.7627C47.2982 13.9679 47.2281 13.2198 47.0878 12.5185C46.9592 11.8055 46.7254 11.2327 46.3864 10.8003C46.0591 10.3561 45.5857 10.134 44.9662 10.134C44.4401 10.134 43.9726 10.2626 43.5634 10.5197C43.1543 10.7652 42.7978 11.1918 42.4939 11.7996C42.2016 12.4074 41.9737 13.2198 41.81 14.2367C41.6464 15.2536 41.5646 16.5277 41.5646 18.0589C41.5646 20.4901 41.8977 22.3077 42.564 23.5116C43.2303 24.7155 44.3174 25.3175 45.8253 25.3175C47.0176 25.3175 48.0697 25.0545 48.9815 24.5285C49.9049 23.9908 50.5887 23.2837 51.0329 22.407C51.3252 22.5823 51.5414 22.822 51.6817 23.1259C51.822 23.4181 51.8921 23.7278 51.8921 24.0551C51.8921 24.511 51.7577 24.9727 51.4888 25.4402C51.22 25.9077 50.805 26.3285 50.2439 26.7026C49.6711 27.0883 48.9464 27.4039 48.0697 27.6493C47.193 27.8831 46.1468 28 44.9311 28Z" fill="#2D1456" />
          <path d="M63.6574 10.134C62.7223 10.134 62.0852 10.508 61.7462 11.2561C61.4189 11.9925 61.2553 12.91 61.2553 14.0088C59.6421 14.0088 58.4264 13.8276 57.6082 13.4652C56.8016 13.1029 56.3983 12.4834 56.3983 11.6068C56.3983 10.9522 56.5912 10.3911 56.977 9.92361C57.3744 9.45606 57.9121 9.07618 58.5901 8.78397C59.2564 8.49176 60.0337 8.28136 60.9221 8.15279C61.8222 8.01252 62.7807 7.94239 63.7977 7.94239C65.0601 7.94239 66.1589 8.05343 67.0941 8.27552C68.0409 8.48591 68.8299 8.83072 69.4612 9.30996C70.7119 10.2801 71.3373 11.8581 71.3373 14.0438V23.196C71.3373 24.1428 71.4893 24.8032 71.7932 25.1772C72.1088 25.5512 72.6757 25.7383 73.494 25.7383H73.6343V27.6493H66.9538L66.1998 25.1772H65.8842C65.3348 25.8318 64.8205 26.3694 64.3412 26.7902C63.8737 27.211 63.3476 27.5149 62.7632 27.7019C62.1787 27.9006 61.4364 28 60.5364 28C59.7415 28 59.0051 27.8831 58.3271 27.6493C57.6491 27.4156 57.0529 27.0532 56.5386 26.5623C56.036 26.0831 55.6327 25.4811 55.3288 24.7564C55.0365 24.02 54.8904 23.1551 54.8904 22.1616C54.8904 20.2096 55.5567 18.766 56.8893 17.8309C58.2336 16.8958 60.25 16.3874 62.9385 16.3056L65.8842 16.2004V14.2192C65.8842 13.0269 65.7615 12.0509 65.516 11.2912C65.2705 10.5197 64.651 10.134 63.6574 10.134ZM60.4662 22.3018C60.4662 24.359 61.1442 25.3876 62.5002 25.3876C63.5756 25.3876 64.4055 24.9551 64.99 24.0902C65.5862 23.2252 65.8842 22.1265 65.8842 20.794V18.0939L64.2711 18.1991C62.8801 18.2693 61.8982 18.6433 61.3254 19.3212C60.7526 19.9875 60.4662 20.981 60.4662 22.3018Z" fill="#2D1456" />
          <path d="M86.3639 25.7383H87.5212V27.6493H75.5279V25.7383H75.6331C76.5332 25.7383 77.217 25.5746 77.6846 25.2473C78.1639 24.9084 78.4035 24.2129 78.4035 23.1609V12.7464C78.4035 11.7412 78.1814 11.0866 77.7372 10.7827C77.3047 10.4671 76.6618 10.3093 75.8085 10.3093H75.7033V8.39825H82.9623L83.6812 11.2035H83.8566C84.3475 10.0697 84.9612 9.25151 85.6977 8.7489C86.4341 8.23461 87.5095 7.97746 88.9239 7.97746C91.6709 7.97746 93.0444 8.94761 93.0444 10.8879C93.0444 11.9866 92.647 12.8399 91.8521 13.4477C91.0572 14.0555 89.935 14.3594 88.4856 14.3594C88.4856 13.436 88.3745 12.7113 88.1524 12.1853C87.9303 11.6477 87.4569 11.3788 86.7322 11.3788C86.1243 11.3788 85.6275 11.6068 85.2418 12.0626C84.856 12.5068 84.5638 13.0328 84.3651 13.6406C84.2248 14.0614 84.1196 14.4822 84.0495 14.9029C83.9676 15.3354 83.915 15.7445 83.8916 16.1302C83.8683 16.516 83.8566 16.8491 83.8566 17.1296V23.3363C83.8566 24.3298 84.0728 24.9785 84.5053 25.2824C84.9378 25.5863 85.5574 25.7383 86.3639 25.7383Z" fill="#2D1456" />
          <path d="M104.88 25.1071C105.347 25.1071 105.792 25.0779 106.212 25.0194C106.645 24.9493 107.048 24.8675 107.422 24.774V27.1058C106.955 27.3279 106.253 27.5325 105.318 27.7195C104.395 27.9065 103.401 28 102.337 28C101.531 28 100.8 27.9006 100.146 27.7019C99.4911 27.5149 98.9358 27.1935 98.4799 26.7376C98.0241 26.2935 97.6734 25.7149 97.4279 25.0019C97.1824 24.2772 97.0597 23.3889 97.0597 22.3369V10.8528H94.3594V9.0119C95.1309 9.0119 95.8206 8.85995 96.4285 8.55604C97.0363 8.25214 97.5214 7.90148 97.8838 7.50407C98.6085 6.74431 99.1579 5.55792 99.532 3.9449H102.513V8.39825H107.107V10.8528H102.513V21.9687C102.513 23.0557 102.694 23.8506 103.056 24.3532C103.43 24.8558 104.038 25.1071 104.88 25.1071Z" fill="#2D1456" />
          <path d="M118.539 10.134C117.604 10.134 116.967 10.508 116.628 11.2561C116.3 11.9925 116.137 12.91 116.137 14.0088C114.524 14.0088 113.308 13.8276 112.49 13.4652C111.683 13.1029 111.28 12.4834 111.28 11.6068C111.28 10.9522 111.473 10.3911 111.858 9.92361C112.256 9.45606 112.794 9.07618 113.472 8.78397C114.138 8.49176 114.915 8.28136 115.804 8.15279C116.704 8.01252 117.662 7.94239 118.679 7.94239C119.942 7.94239 121.04 8.05343 121.975 8.27552C122.922 8.48591 123.711 8.83072 124.343 9.30996C125.593 10.2801 126.219 11.8581 126.219 14.0438V23.196C126.219 24.1428 126.371 24.8032 126.675 25.1772C126.99 25.5512 127.557 25.7383 128.375 25.7383H128.516V27.6493H121.835L121.081 25.1772H120.766C120.216 25.8318 119.702 26.3694 119.223 26.7902C118.755 27.211 118.229 27.5149 117.645 27.7019C117.06 27.9006 116.318 28 115.418 28C114.623 28 113.886 27.8831 113.208 27.6493C112.531 27.4156 111.934 27.0532 111.42 26.5623C110.917 26.0831 110.514 25.4811 110.21 24.7564C109.918 24.02 109.772 23.1551 109.772 22.1616C109.772 20.2096 110.438 18.766 111.771 17.8309C113.115 16.8958 115.131 16.3874 117.82 16.3056L120.766 16.2004V14.2192C120.766 13.0269 120.643 12.0509 120.397 11.2912C120.152 10.5197 119.532 10.134 118.539 10.134ZM115.348 22.3018C115.348 24.359 116.026 25.3876 117.382 25.3876C118.457 25.3876 119.287 24.9551 119.871 24.0902C120.468 23.2252 120.766 22.1265 120.766 20.794V18.0939L119.153 18.1991C117.761 18.2693 116.78 18.6433 116.207 19.3212C115.634 19.9875 115.348 20.981 115.348 22.3018Z" fill="#2D1456" />
          <path d="M140.719 0.36819H149.364V23.2311C149.364 24.2597 149.638 24.9376 150.188 25.2649C150.749 25.5805 151.468 25.7383 152.344 25.7383H152.485V27.6493H145.524L144.524 24.8441H144.191C143.642 25.861 142.917 26.6441 142.017 27.1935C141.128 27.7312 140.012 28 138.668 28C136.283 28 134.477 27.1993 133.25 25.598C131.976 23.9733 131.339 21.4602 131.339 18.0589C131.339 14.6341 131.97 12.086 133.232 10.4145C134.46 8.78981 136.248 7.97746 138.598 7.97746C139.848 7.97746 140.924 8.23461 141.824 8.7489C142.724 9.2632 143.431 9.96452 143.946 10.8528H144.191C144.168 10.5139 144.144 10.1924 144.121 9.88854C144.098 9.57295 144.074 9.26905 144.051 8.97683C144.039 8.56773 144.01 8.10019 143.963 7.5742C143.928 7.04822 143.911 6.68002 143.911 6.46963V4.48842C143.911 3.54164 143.642 2.93383 143.104 2.665C142.566 2.39616 141.865 2.26174 141 2.26174H140.719V0.36819ZM140.211 25.3175C141.59 25.3175 142.566 24.7155 143.139 23.5116C143.42 22.9038 143.618 22.144 143.735 21.2323C143.852 20.3089 143.911 19.2277 143.911 17.9887C143.911 16.8082 143.852 15.7621 143.735 14.8503C143.618 13.9269 143.42 13.1497 143.139 12.5185C142.555 11.2561 141.579 10.6249 140.211 10.6249C139.03 10.6249 138.177 11.2619 137.651 12.536C137.137 13.775 136.879 15.6043 136.879 18.0238C136.879 20.4433 137.137 22.2667 137.651 23.4941C138.165 24.7097 139.019 25.3175 140.211 25.3175Z" fill="#2D1456" />
          <path d="M164.004 28C160.977 28 158.662 27.135 157.061 25.4051C155.483 23.6986 154.694 21.2732 154.694 18.129C154.694 14.7744 155.471 12.2321 157.026 10.5022C158.557 8.79566 160.749 7.94239 163.601 7.94239C166.243 7.94239 168.294 8.67293 169.756 10.134C170.48 10.8587 171.036 11.7587 171.421 12.8341C171.807 13.9094 172 15.1601 172 16.5861V18.4271H160.235C160.27 19.631 160.404 20.6654 160.638 21.5304C160.872 22.3953 161.187 23.1083 161.585 23.6694C162.38 24.7681 163.531 25.3175 165.039 25.3175C166.22 25.3175 167.248 25.0545 168.125 24.5285C169.002 23.9908 169.662 23.2837 170.106 22.407C170.749 22.7577 171.071 23.3421 171.071 24.1603C171.071 24.6746 170.93 25.1655 170.65 25.6331C170.369 26.0889 169.937 26.4922 169.352 26.8428C168.768 27.1935 168.031 27.474 167.143 27.6844C166.266 27.8948 165.22 28 164.004 28ZM163.671 10.2743C162.666 10.2743 161.865 10.771 161.269 11.7646C160.685 12.7581 160.363 14.2133 160.305 16.1302H166.512C166.512 15.2185 166.459 14.4003 166.354 13.6756C166.261 12.9509 166.097 12.3373 165.863 11.8347C165.384 10.7944 164.653 10.2743 163.671 10.2743Z" fill="#2D1456" />
        </svg>
      </div>
      <div>
        <ici>
          <TextGradientBGFX className="text-lg font-serif">
            <span className="text-xl mb-5 block">Bienvenue,</span>
            <p className="mb-4">
              Chez incartade on souhaite rendre les <span className="font-bold">séjours courts</span> et <span className="font-bold">éco-responsables</span> accessibles à tous, en mettant en valeur les talents et la passion des <span className="font-bold">guides outdoor</span>.
            </p>
            <p className="mb-4">Vous êtes une personne qualifiée pour enseigner et encadrer des activités de loisirs en plein air telles que l'escalade, la randonnée, le vélo, l'alpinisme, la voile, le kayak, le ski, et bien d'autres...</p>
            <p className="mb-12">Nous vous accompagnons et développons des outils qui mettent en valeur votre expertise, facilitent l'accès, réduisent les coûts et simplifient les réservations.</p>
            <p className="font-bold pb-10">
              Si vous souhaitez en savoir plus n’hésitez pas à nous contacter via cet email
              <br />
              <a className="-rotate-2 inline-block mt-1 bg-gray-950 text-white font-sans px-2 py-1" href="&#109;&#97;&#105;&#x6c;&#x74;&#x6f;&#x3a;&#x73;&#x61;&#x6c;&#x75;&#116;&#64;&#105;&#x6e;&#x63;&#97;&#114;&#116;&#97;&#x64;&#101;&#x2e;&#99;&#111;&#x6d;">
                &#x73;&#x61;&#x6c;&#x75;&#116;&#64;&#105;&#x6e;&#x63;&#97;&#114;&#116;&#97;&#x64;&#101;&#x2e;&#99;&#111;&#x6d;
              </a>
            </p>
          </TextGradientBGFX>
        </ici>
      </div>
    </div>
  );
};

export default SideDesc;
