import React from "react";
import SideDesc from "./components/SideDesc";

function App() {
  return (
    <>
      <div className="bodyZone">
        <div className="flex min-h-screen">
          <div className="md:w-1/12">
            <div className="bg-gradientFX fixed md:w-1/12 md:h-screen"></div>
          </div>
          <SideDesc />
          <div className="flex-1">
            <div className="bg-gradientFX fixed md:h-screen md:w-full"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
