import React from "react";

const TextGradientBGFX = ({ children, className }) => {
  // Concaténation des classes si className est défini
  const classes = className ? `text_gradientBGFX ${className}` : "text_gradientBGFX";

  return (
    <div className={classes}>
      <div>{children}</div>
    </div>
  );
};

export default TextGradientBGFX;
